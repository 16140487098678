import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Layout from '../../layouts/Website'
import Paper, { PaperClean } from '../../components/Paper'
import Heading from '../../components/Heading'

import { Description, Picture } from '../../views/web/Works'

const useStyles = makeStyles((theme) => ({

}));

const data = [
  {
    data: {
      title: 'nvisible.studio',
      link: 'https://nvisible.studio',
      description: "nvisible.studio is the independent research practice of Finnish designer and filmmaker Emilia Tapprest. Her research explores visceral implications of rapid technocultural developments through speculative worldbuilding and cinema.",
    },
    images: [
      {
        src: require("../../assets/images/works/nvisible.jpg").default,
        altText: "nvisible.studio",
        caption: "ZHŌUWÉI.NETWORK | Video still",
      }
    ]
  },
  {
    data: {
      title: 'liminal.vision',
      link: 'https://liminal.vision',
      description: "liminal.vision explores value paradigms in emerging technocultural developments through speculative worldbuilding, material artifacts and immersive media. Grounded in the research field on ‘affect’ and its use through datafication, we unpack the design of affective undertones in different social systems by forward-engineering them from a critical perspective.",
    },
    images: [
      {
        src: require("../../assets/images/works/liminal.png").default,
        altText: "liminal.vision",
        caption: "ZHŌUWÉI.NETWORK | Video still",
      }
    ]
  },
  {
    data: {
      title: 'demomovingimage.com',
      link: 'https://demomovingimage.com',
      description: "DEMO is a curatorial platform exploring the aesthetic and political potentialities of the moving image, regularly presenting experimental films concerning subaltern conditions and speculating on alternative modes of coexistence.",
    },
    images: [
      {
        src: require("../../assets/images/works/demo.png").default,
        altText: "demo moving image",
        caption: "DEMO | Homepage",
      }
    ]
  },
  {
    data: {
      title: 'sonzai.zone',
      link: 'https://sonzai.zone',
      description: "Sonzai Zone is a speculative fiction film on intimacy and loneliness after the normalization of ambient communication media. An unlikely encounter between Yún and Souvd takes place in a near-future where social interactions are largely based on the mediation of human presence, known as ‘Sonzai-kan’. Shifting between XR games, Immersion Arcades and spatial home displays, their insidiously orchestrated relationship escalates into extreme idealization. Meanwhile, Souvd’s ex-girlfriend Ntzumi launches into undercover investigation.",
    },
    images: [
      {
        src: require("../../assets/images/works/sonzai.png").default,
        altText: "sonzai.zone",
        caption: "SONZAI ZONE | Video still",
      }
    ]
  },
  {
    data: {
      title: 'sonzai.media',
      link: 'https://sonzai.media',
      description: "Sonzai Media is a research proposal for LACMA Art + Technology Lab by Emilia Tapprest, Andrei Iovcev and Victor Evink. It aims to kindle broad public discourse on ambient communication technologies while investigating broader value paradigms driving technological domestication - including expectations that we have about our close, interpersonal relationships in times of crisis.",
    },
    images: [
      {
        src: require("../../assets/images/works/sonzai-media.svg").default,
        altText: "sonzai.media",
        caption: "",
      }
    ]
  },
  {
    data: {
      title: 'zhouwei.network',
      link: 'https://zhouwei.network',
      description: "Zhōuwéi.Network is a worldbuilding project that provides windows into three speculative near-future societies: Dolphin Waves (New York City), a corporate playbour & wellness hub; Dragonfly (Netherlands), a technocratic state focusing on safety and harmony; and Project Gecko (multiple locations), a network of decentralised liquid democracies focused on inner healing trough body movement. For each, we contrast how ideology and its application through technology shapes everyday experiences in visceral ways.",
    },
    images: [
      {
        src: require("../../assets/images/works/zhouwei.jpg").default,
        altText: "zhouwei.network",
        caption: "",
      }
    ]
  }
]

const IndexPage = () => {
  const classes = useStyles();
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <Heading title='Portfolio' />
          </Paper>
        </Grid>

        {data.map((obj, index) => {
         return <Grid item xs={12} key={index}>
          <Paper>
            <Grid container>
              <Grid item xs={12} md={6}>
                <PaperClean>
                  <Picture data={obj.images}/>
                </PaperClean>
              </Grid>
              <Grid item xs={12} md={6}>
                <PaperClean>
                  <Description data={obj.data}/>
                </PaperClean>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        })}
    
    </Layout>
  )
}

export default IndexPage
