import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GlitchText from 'react-glitch-effect/core/GlitchText'
import { LinkTab } from '../../../components/Link'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '500'
  },
  image: {
    height: 'auto',
    width: '100%'
  }
}));

const Picture = ({data}) => {
  const classes = useStyles();
  return (
    <>
      <img src={data[0].src}
        className={classes.image}
      />
    </>
  )
} 

const Description = ({data}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant='h5' component='h2'
        color='textPrimary' align='center'
        className={classes.title}
      >
        {data.title}
      </Typography>
      <Typography variant='body2' component='p'
        align='left' paragraph
      >
        {data.description}
      </Typography>
      <Typography variant='body1' component='p'
        align='left'
      >
        <LinkTab
          href={data.link}
        >
          <GlitchText>
          website
          </GlitchText>
        </LinkTab>
      </Typography>
    </>
  )
}  

export {Description, Picture}